import logoGrohe from "../images/reference/grohe/logo.png"
import LogoFSI from "../images/reference/fsivut/logofsi.png"
import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import LogoIFE from "../images/reference/ife/logoife.png"
import LogoKiwi from "../images/reference/kiwi/logo.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoLDF from "../images/reference/ldf/logoldf.svg"
import BGKiwi from "../images/reference/bg-kiwi.png"
import BGPH from "../images/reference/bg-ph.png"
import BGFSI from "../images/reference/bg-fsi.png"
import BGIFE from "../images/reference/bg-ife.png"
import BGHelcel from "../images/reference/bg-helcel.png"
import BGHannah from "../images/reference/bg-hannah.png"
import BGGrohe from "../images/reference/bg-grohe.png"
import BGLDF from "../images/reference/bg-ldf.png"
import logoSA from "../images/reference/student-agency/logoSA.png"
import logoSZ from "../images/reference/snowboard-zezula/logo-sz.png"
import logocontours from "../images/reference/contours/logocontours.png"
import logofekt from "../images/reference/fektvut/logofekt.png"
import logohvezdarna from "../images/reference/hvezdarna/logohvezdarna2.png"
import logojena from "../images/reference/jena/logojena.png"
import logomd from "../images/reference/moravske-drevostavby/logo-moravskedrevostavby.png"
import logomupo from "../images/reference/mupo/logomupo.png"
import logoskinners from "../images/reference/skinners/logo-black.png"
import logosynthon from "../images/reference/synthon/logosynthon.png"
import logoHannah from "../images/reference/hannah/logo.png"
import logoSib from "../images/reference/study-in-brno/logo.svg"
import logoSmartLashes from "../images/reference/smart-lashes/logo.png"
import LogoCarlini from "../images/reference/carlini/logo.jpg"
import BGContours from "../images/reference/bg-contours.png"
import BGHvezdarna from "../images/reference/bg-hvezdarna.png"
import BGJena from "../images/reference/bg-jena.png"
import BGMD from "../images/reference/bg-md.png"
import BGMupo from "../images/reference/bg-mupo.png"
import BGSA from "../images/reference/bg-sa.png"
import BGSIB from "../images/reference/bg-sib.png"
import BGSkinners from "../images/reference/bg-skinners.png"
import BGSmartLashes from "../images/reference/bg-smart-lashes.png"
import BGSynthon from "../images/reference/bg-synthon.png"
import BGSZ from "../images/reference/bg-sz.png"
import BGFEKT from "../images/reference/bg-fekt.png"
import BGBorgers from "../images/reference/bg-borgers.png"
import logoBorgers from "../images/reference/borgers/logo.jpg"
import BGCarlini from "../images/reference/bg-carlini.png"
import BGWTB from "../images/reference/bg-wtb.png"
import logoWTB from "../images/reference/wtb/logo.png"
import BGSpolecnostE from "../images/reference/bg-spolecnost-e.jpg"
import LogoSpolecnostE from "../images/reference/spolecnost-e/logo.jpg"

export const MARKETING_REFERENCES_FULL = [
  {
    src: BGKiwi,
    alt: "",
    logo: LogoKiwi,
    logoAlt: "logo společnosti kiwi.com",
    title: "Kiwi.com",
    link: "reference/kiwi",
  },
  {
    src: BGFSI,
    alt: "",
    logo: LogoFSI,
    logoAlt: "logo fakulty strojního inženýrství",
    title: "FSI VUT",
    link: "reference/fsivut",
  },
  {
    src: BGHelcel,
    alt: "",
    logo: LogoHelcel,
    logoAlt: "logo společnosti Helcel",
    title: "HELCEL",
    link: "reference/helcel",
  },
  {
    src: BGIFE,
    alt: "",
    logo: LogoIFE,
    logoAlt: "logo společnosti IFE",
    title: "IFE-CR",
    link: "reference/ife",
  },
  {
    src: BGHannah,
    alt: "",
    logo: logoHannah,
    logoAlt: "logo společnosti Hannah",
    title: "Hannah",
    link: "reference/hannah",
  },
  {
    src: BGPH,
    alt: "",
    logo: LogoPlaneta,
    logoAlt: "logo společnosti Planetaher.cz",
    title: "Planetaher.cz",
    link: "reference/planetaher",
  },
  {
    src: BGWTB,
    alt: "",
    logo: logoWTB,
    logoAlt: "logo společnosti William & Thomas Artisan Bakery",
    title: "William & Thomas Artisan Bakery",
    link: "reference/william-thomas-artisan-bakery",
  },
  {
    src: BGBorgers,
    alt: "",
    logo: logoBorgers,
    logoAlt: "logo společnosti Borgers",
    title: "Borgers",
    link: "reference/borgers",
  },
  {
    src: BGSIB,
    alt: "",
    logo: logoSib,
    logoAlt: "logo Study in Brno",
    title: "Study in Brno",
    link: "reference/study-in-brno",
  },
  {
    src: BGSkinners,
    alt: "",
    logo: logoskinners,
    logoAlt: "logo Skinners",
    title: "SKINNERS",
    link: "reference/skinners",
  },
  {
    src: BGCarlini,
    alt: "",
    logo: LogoCarlini,
    logoAlt: "logo společnosti carlini",
    title: "Carlini",
    link: "reference/carlini",
    smallerImg: true,
  },
  {
    src: BGSpolecnostE,
    alt: "",
    logo: LogoSpolecnostE,
    logoAlt: "logo společnosti E",
    title: "Společnost E",
    link: "reference/spolecnost-e",
    smallerImg: true,
  },
  {
    src: BGGrohe,
    alt: "",
    logo: logoGrohe,
    logoAlt: "logo společnosti Grohe",
    title: "Grohe",
    link: "reference/grohe",
  },
  {
    src: BGJena,
    alt: "",
    logo: logojena,
    logoAlt: "logo Jena nábytek",
    title: "JENA",
    link: "reference/jena",
  },
  {
    src: BGSZ,
    alt: "",
    logo: logoSZ,
    logoAlt: "logo Snowboard Zezula",
    title: "SNOWBOARD ZEZULA",
    link: "reference/snowboard-zezula",
  },
  {
    src: BGMupo,
    alt: "",
    logo: logomupo,
    logoAlt: "logo společnosti Müpo",
    title: "MÜPO",
    link: "reference/mupo",
  },
  {
    src: BGSA,
    alt: "",
    logo: logoSA,
    logoAlt: "logo Student Agency",
    title: "STUDENT AGENCY",
    link: "reference/student-agency",
  },
  {
    src: BGSmartLashes,
    alt: "",
    logo: logoSmartLashes,
    logoAlt: "logo Smart Lashes",
    title: "SMART LASHES",
    link: "reference/smart-lashes",
  },
  {
    src: BGHvezdarna,
    alt: "",
    logo: logohvezdarna,
    logoAlt: "logo Hvězdárny a planetária Brno",
    title: "Hvězdárna a planetárium Brno",
    link: "reference/hvezdarna-a-planetarium-brno",
  },
  {
    src: BGMD,
    alt: "",
    logo: logomd,
    logoAlt: "logo společnosti Moravské Dřevostavby",
    title: "MORAVSKÉ DŘEVOSTAVBY",
    link: "reference/moravske-drevostavby",
  },
  {
    src: BGContours,
    alt: "",
    logo: logocontours,
    logoAlt: "logo společnosti Contours",
    title: "Contours",
    link: "reference/contours",
  },
  {
    src: BGLDF,
    alt: "",
    logo: LogoLDF,
    logoAlt: "logo Lesnické a dřevařské fakulty MENDELU",
    title: "LDF MENDELU",
    link: "reference/ldf-mendelu",
  },
  {
    src: BGFEKT,
    alt: "",
    logo: logofekt,
    logoAlt:
      "logo fakulty eletrotechniky a komunikačních technologií VUT v Brně",
    title: "FEKT VUT",
    link: "reference/fektvut",
  },
  {
    src: BGSynthon,
    alt: "",
    logo: logosynthon,
    logoAlt: "logo společnosti Synthon",
    title: "Synthon",
    link: "reference/synthon",
  },
]
