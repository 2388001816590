import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import {
  Banner,
  BannerStyle,
  Cards,
  ContainerCustom,
  PaddingWrapper,
  TitleH2Center,
} from "@igloonet-web/shared-ui"

import { MARKETING_REFERENCES_FULL } from "../../model/reference-marketing-references-full"

const ReferenceMarketing: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Marketingové reference | igloonet</title>
        <meta
          name="description"
          content="Reference na zajímavé marketingové zakázky, které jsme realizovali pro naše klienty."
        />
      </Helmet>
      <Banner
        style={BannerStyle.Reference}
        orangeHeading="Marketingové "
        blackHeading="reference"
        text="V on&#8209;line marketingu se soustředíme na dlouhodobou spolupráci a&nbsp;celkovou strategii. Nebráníme se ale ani dílčím zakázkám a&nbsp;jednorázovým projektům."
      />

      <ContainerCustom>
        <PaddingWrapper>
          <TitleH2Center>Naši klienti</TitleH2Center>

          <Cards data={MARKETING_REFERENCES_FULL} />

          {/* <ReferencesFull anotherReferences={marketingReferences} /> */}
        </PaddingWrapper>
      </ContainerCustom>
    </Layout>
  )
}

export default ReferenceMarketing
